import Button from "../UI/Button";
import classes from "./links.module.css";

const Links = () => {
  return (
    <section className={classes.buttons__wrapper}>
      <Button link="https://www.facebook.com/lekka.forma" name="Facebook" />
      <Button link="https://www.instagram.com/lekka.forma/?hl=pl" name="Instagram" />
      <Button link="https://www.homebook.pl/profil/1297060/lekka-forma-pracownia-projektowa" name="Homebook" />
      <Button link="https://www.trojmiasto.pl/Lekka-Forma-o89115.html" name="Trojmiasto.pl" />
      <Button link="https://g.co/kgs/bjsxYX" name="O nas w Google" />
      <Button link="https://g.page/r/CWzVof0up8fLEBM/review" name="Wystaw nam opinię!" />
    </section>
  );
};

export default Links;
