import IMG_URL from "../../assets/girls.jpg";
import classes from "./photo.module.css";

const Photo = () => {
    return (
        <div>
            <img src={IMG_URL} alt="LF Logo" className={classes.photo} />
        </div>
    );
};

export default Photo;